.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid $color-main;
  border-radius: 6px;
  width: 100%;
  height: 46px;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  .modal-footer & {
    font-weight: 700;
  }

  .alert-footer & {
    font-weight: 700;
  }

  .filter-controls & {
    font-weight: 500;
  }

  .modal-measurements & {
    max-width: 248px;
  }
}

.btn-add {
  color: $color-highlight;
  background-color: $color-main;
  font-weight: bold;
  padding: 0 32px;

  .alert-footer & {
    padding: 0;
  }

  .event-controls &:nth-child(1) {
    background-color: transparent;
  }
}

.btn-big {
  font-size: 18px;
  border-radius: $border-radius;
  height: 56px;
  font-weight: bold;
}

.btn-main {
  color: $color-highlight;
  background-color: $color-main;

  &.recommended-btn {
    margin-bottom: 13px;
  }
}

.btn-primary {
  background-color: transparent;
  color: $color-main;

  .alert-footer & {
    background-color: #e9edf7;
  }
}

.btn-secondary {
  color: $color-main;
  background-color: transparent;
  border-color: transparent;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: $color-text-high;
  background-color: transparent;
  border: none;
  width: fit-content;
  margin-bottom: 3vh;
}

.btn-link-icon {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.btn-control {
  width: 46px;
  min-width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background-color: $color-primary;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.btn-control-icon {
  width: 16px;
  height: 16px;
}

.btn-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 18px;
}

.btn-content {
  width: fit-content;
  padding: 0 8px;
}

.btn-danger {
  background-color: #f26563;
  color: $color-highlight;
  border: none;
}

.btn-edit-measurement {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 9px;
  padding: 8px 12px;
  margin-top: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: $color-main;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in;

  .form-start-label &:first-child:last-child {
    margin-bottom: 20px;
  }

  &:hover {
    background-color: $color-primary;
  }
}

.btn-dashboard {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-plug {
  margin: 20px auto 0;
  font-weight: 700;
  width: 222px;
}

.btn-clean {
  border: none;
  background: transparent;
  padding: 4px;
}
