.table-controls-icon {
  width: 14px;
  height: 14px;
}

.table-dropdown-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 12px;
  background-color: $color-secondary;
  position: absolute;
  top: calc(100% + 12px);
  right: -20px;
  min-width: 170px;
  z-index: 2;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2),
    0 30px 70px 0 rgba(26, 34, 64, 0.15), 0 0 0 1px rgba(136, 143, 170, 0.1);

  .event-tr:last-child & {
    top: unset;
    bottom: 50px;
  }

  .event-tr:only-child & {
    bottom: auto;
  }
}

.table-dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: $color-text;
  background-color: transparent;
  border: none;
  text-align: left;
  white-space: nowrap;

  &:hover:not(:disabled) {
    cursor: pointer;
    color: $color-main;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
