.landing-features {
  position: relative;
  z-index: 1;
  background: #fff;

  & .landing-features__item-box {
    padding-bottom: 120px;
    gap: 40px;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  &__item-message {
    flex: 1;
    gap: 24px;
  }

  &__item-number {
    position: relative;
    color: #fff;
    font-family: $oswald-regular;
    font-size: 80px;
    margin-top: 32px;
    font-weight: 500;

    &::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      color: transparent;
      font-size: inherit;
      font-weight: 700;
    }

    &:not(.landing-features__item-number--firefox) {
      &::before {
        -webkit-text-stroke: 2px #6c63ff;
      }
    }

    &.landing-features__item-number--firefox {
      text-shadow: 2px 2px 0 #6c63ff, -2px 2px 0 #6c63ff, 2px -2px 0 #6c63ff,
        -2px -2px 0 #6c63ff;
    }
  }

  &__item-description {
    flex-direction: column;
  }

  &__item-label {
    font-family: $outfit-medium;
    color: $light-dark-text;
    font-size: 14px;
    line-height: 24px;
  }

  &__item-title {
    color: $dark-text;
    font-family: $oswald-regular;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.8px;

    span {
      font-family: $oswald-extra-light;
    }
  }

  &__img-box {
    position: relative;
    width: 520px;
    height: 520px;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 1000px) {
  .landing-features {
    &__item-message {
      flex-direction: column;
      text-align: center;
      gap: 0;
    }

    &__item-number {
      padding-top: 0;
    }

    &__item-label {
      margin: -14px 0 16px;
    }

    & .landing-features__item-box {
      flex-direction: column;

      &:nth-child(even) {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 600px) {
  .landing-features {
    &__img-box {
      width: 100%;
      height: calc(100vw - 32px);

      img {
        width: 100vw;
      }
    }
  }
}
