.code-field {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: $color-text-high;
    width: 100%;
    max-width: 74px;
    height: 74px;
    background-color: $color-highlight;
    border: none;
    border-radius: 16px;
    text-align: center;
    border: 2px solid transparent;

    &:focus {
      border-color: $color-main;
    }
  }
}

.code-link {
  display: block;
  font-size: 15px;
  font-weight: bold;
  color: $color-main;
  cursor: pointer;
  height: fit-content;
  margin-bottom: 10px;
}
