.landing-benefits {
  position: relative;
  z-index: 1;
  margin-bottom: 140px;

  &__title {
    margin-bottom: 40px;
  }

  &__box {
    position: relative;
    flex-wrap: wrap;

    &:after {
      position: absolute;
      top: 50%;
      left: calc(50% - 3px);
      width: calc(100vw + 6px);
      transform: translateX(-50%);
      content: '';
      height: calc(50% + 145px);
      background: #fff;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    flex-direction: column;
    padding: 24px;
    width: 25%;
    min-height: 200px;
    background: $main-dark-bg;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:nth-child(1) {
      border-radius: 16px 0 0 16px;
    }
    &:nth-child(4) {
      border-radius: 0 16px 16px 0;
    }
  }

  &__icon-box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 24px;
    background: rgba(255, 255, 255, 0.1);
  }

  &__description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-family: $outfit-light;
  }
}

@media (max-width: 1000px) {
  .landing-benefits {
    &__item {
      width: 50%;

      &:nth-child(1) {
        border-radius: 16px 0 0 0;
      }
      &:nth-child(2) {
        border-radius: 0 16px 0 0;
      }
      &:nth-child(3) {
        border-radius: 0 0 0 16px;
      }
      &:nth-child(4) {
        border-radius: 0 0 16px 0;
      }
    }
  }
}

@media (max-width: 560px) {
  .landing-benefits {
    &__item {
      width: 100%;

      &:nth-child(1) {
        border-radius: 16px 16px 0 0;
      }
      &:nth-child(2) {
        border-radius: 0;
      }
      &:nth-child(3) {
        border-radius: 0;
      }
      &:nth-child(4) {
        border-radius: 0 0 16px 16px;
      }
    }
  }
}
