.datepicker .react-datepicker__input-container,
.timepicker .react-datepicker__input-container {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translate(0, -50%);
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:not(:placeholder-shown) {
    content: none;
  }
}

.datepicker .react-datepicker__input-container {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.667 2.667h-1.333V2a.667.667 0 0 0-1.333 0v.667H6V2a.667.667 0 0 0-1.334 0v.667H3.334a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.333a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2zm.667 10a.667.667 0 0 1-.667.666H3.334a.667.667 0 0 1-.667-.666V8h10.667v4.667zm0-6H2.667v-2A.667.667 0 0 1 3.334 4h1.333v.667a.667.667 0 0 0 1.334 0V4h4v.667a.667.667 0 1 0 1.333 0V4h1.333a.667.667 0 0 1 .667.667v2z' fill='%239E9E9E'/%3E%3C/svg%3E%0A");
  }
}

.timepicker .react-datepicker__input-container {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 7.333H8.668V4.667a.667.667 0 0 0-1.333 0V8a.667.667 0 0 0 .667.667h2a.667.667 0 0 0 0-1.334zm-2-6a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zm0 12A5.333 5.333 0 1 1 8 2.668a5.333 5.333 0 0 1 0 10.665z' fill='%239E9E9E'/%3E%3C/svg%3E%0A");
  }
}

.datepicker .datepicker-input,
.timepicker .datepicker-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  border: 1px solid $color-border;
  border-radius: 6px;
  padding: 14px 13px 14px 35px;
  width: 100%;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.datepicker-filter {
  margin-top: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 26px;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    padding-right: 0;
  }
}

.datepicker-filter .react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

.datepicker .datepicker-filter-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  border: 1px solid $color-border;
  border-radius: 6px;
  padding: 5px 25px 5px 35px;
  width: 100%;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.datepicker .react-datepicker-ignore-onclickoutside,
.timepicker .react-datepicker-ignore-onclickoutside {
  border-color: $color-main;
}

.datepicker .react-datepicker__header {
  background-color: $color-highlight;
  padding-top: 17px;
  border: none;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.datepicker .react-datepicker__triangle {
  display: none;
}

.datepicker .react-datepicker {
  border: none;
  border-radius: 6px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08),
    0 15px 35px -5px rgba(17, 24, 38, 0.2), 0 0 0 1px rgba(152, 161, 178, 0.1);
  background-color: $color-highlight;
}

.datepicker .react-datepicker__month {
  margin: 0;
  padding: 16px;
}

.datepicker .react-datepicker__day {
  font-size: 14px;
  font-weight: 500;
  color: #464f60;
  width: 40px;
  height: 40px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.datepicker {
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: $color-main;
    color: $color-highlight;

    &:hover {
      background-color: $color-main;
      color: $color-highlight;
    }
  }

  .react-datepicker__day--today {
    background-color: transparent;
    border: 1px solid $color-main;
    color: $color-main;
    border-radius: 6px;

    &.react-datepicker__day--in-selecting-range {
      border: none;
    }
  }

  .react-datepicker__day--in-selecting-range {
    background-color: $color-primary !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background-color: $color-primary !important;
    }
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: $color-main !important;
    color: $color-highlight;

    &:hover {
      background-color: $color-main !important;
      color: $color-highlight;
    }
  }

  .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
    border-radius: 6px !important;
  }

  .react-datepicker__day--selecting-range-start:not(.react-datepicker__day--selecting-range-end) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    &:hover {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &:hover {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .react-datepicker__current-month {
    font-size: 14px;
    font-weight: 500;
    color: #171c26;
  }

  .react-datepicker__navigation {
    width: 24px;
    min-width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    top: 16px;
  }

  .react-datepicker__navigation--previous {
    left: 22px;
  }

  .react-datepicker__navigation--next {
    right: 22px;
  }

  .react-datepicker__navigation-icon {
    width: 100%;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 5L8 10L13 5' stroke='%231E1E1E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    top: unset;
    left: unset;
    right: unset;

    &::after,
    &::before {
      content: none;
    }
  }

  .react-datepicker__navigation-icon--next {
    transform: rotate(-90deg);
  }

  .react-datepicker__navigation-icon--previous {
    transform: rotate(90deg);
  }

  .react-datepicker__day-names {
    padding-top: 15px;
    margin-bottom: -15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 15px 16px 0 16px;
  }

  .react-datepicker__day-name {
    font-size: 12px;
    font-weight: 500;
    color: #687182;
    letter-spacing: 0.36;
    text-transform: uppercase;
    line-height: 1.5;
    margin: 0;
  }
}

.timepicker {
  .react-datepicker {
    border-radius: 6px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08),
      0 15px 35px -5px rgba(17, 24, 38, 0.2), 0 0 0 1px rgba(152, 161, 178, 0.1);
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: $color-highlight;
    border-bottom: 1px solid $color-border;
  }

  .react-datepicker-time__header {
    font-size: 14px;
    font-weight: 500;
    color: $color-text-high;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: $color-text-high;
  }

  .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: $color-main !important;
    color: $color-highlight !important;
    font-weight: 400 !important;
    font-size: 12px !important;
  }
}

.datepicker-icon {
  width: 16px;
  min-width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 19px;

  @media (max-width: 390px) {
    display: none;
  }
}

.datepicker,
.timepicker {
  @media (max-width: 390px) {
    .d-flex {
      gap: 10px;
    }
  }
}

.react-datepicker__day--in-range {
  background-color: $color-primary !important;
}
