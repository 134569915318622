.notification {
  position: absolute;
  top: 150px;
  right: 150px;
  width: auto;
  height: auto;
  background-color: $color-highlight;
  box-shadow: 0px 4px 6px rgba(233, 237, 247, 0.55),
    0px -1px 6px rgba(233, 237, 247, 0.55);
  border-radius: 10px;
  z-index: 999;
}

.notification-wrapper {
  position: relative;
  padding: 20px 56px;
}

.notification-title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: $color-text-high;
}

.notification-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: $color-text;
}

.notification-close-icon {
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
}
