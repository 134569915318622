.load {
  border: 1px dashed #868fa0;
  border-radius: 16px;
  min-height: 120px;
}

.load-input {
  display: none;
}

.load-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #464f60;
}

.load-label {
  font-size: 14px;
  font-weight: bold;
  color: #868fa0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  min-height: 120px;
  padding: 16px;
  cursor: pointer;

  &.isDisabled {
    cursor: default;
  }
}

.load-icon-add {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.load-icon-filename {
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin-right: 7px;
}

.load-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 11px;
}

.load-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
}

.load-btn {
  font-size: 14px;
  font-weight: 500;
  background-color: $color-primary;
  border: none;
  height: 36px;
  width: fit-content;
  padding: 0 12px;
  white-space: nowrap;
}

.load-loader.loader {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.load-file {
  font-size: 16px;
  font-weight: 500;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.load-delete {
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background-color: $color-primary;
  margin-left: auto;
}

.load-delete-icon {
  width: 18px;
  height: 18px;
}
