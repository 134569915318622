.search {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $color-highlight;
  border-radius: $border-radius;
}

.search-field {
  height: 46px;
  padding: 0 35px 0 10px;
  width: 100%;
  border: none;
  width: 20vw;
  border-radius: $border-radius;
  font-size: 14px;
  font-weight: 400;
  color: $color-text-high;

  @media (max-width: 515px) {
    width: 40vw;
  }
}

.search-icon-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
}
