.d-flex {
  display: flex;
}

.a-center {
  align-items: center;
}

.jc-center {
  justify-content: center;
}

.p-rel {
  position: relative;
}

.isDisabled {
  opacity: 0.5;
  cursor: default;
}

.shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $color-shadow;
}

.cursor-pointer {
  cursor: pointer;
}

.is-color {
  color: #a3aed0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-8 {
  margin-bottom: 8px;
}
