.segment {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  padding: 5px 4px;
  border-radius: 6px;
  width: fit-content;
  margin-bottom: 10px;
}

.segment-item {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: $color-text;
  letter-spacing: 0.3px;
  padding: 9px 13px;
  border-radius: 4px;
  cursor: pointer;

  &.isActive {
    background-color: $color-highlight;
    color: $color-main;
  }

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
  }

  .isDisabled & {
    cursor: default;
  }
}
