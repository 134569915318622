.landing-contact-us {
  position: relative;
  padding-bottom: 120px;
  overflow: hidden;

  &__container {
    position: relative;
    flex-direction: column;
    z-index: 1;
  }

  &__title {
    margin-bottom: 64px;
  }

  &__form {
    flex-direction: column;
  }

  &__form-notification {
    gap: 8px;
    margin-bottom: 16px;
    padding: 0 16px;
    height: 32px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 12px;
    letter-spacing: 0.2px;

    & * {
      font-family: $outfit-light;
    }
  }

  &__input-box {
    position: relative;
    width: 400px;
    margin-bottom: 40px;
  }

  &__input {
    width: 100%;
    padding: 0 16px;
    color: #fff;
    border: none;
    border-bottom: 1px solid $light-input-text;
    background: none;
    line-height: 24px;
    height: 40px;
    font-family: $outfit-medium;
    font-size: 14px;

    &::placeholder {
      color: #fff;
    }

    &--textarea {
      display: block;
      overflow: hidden;
      resize: none;
      padding-top: 7px;
    }
  }

  &__input-error {
    position: absolute;
    left: 0;
    bottom: -22px;
    padding: 0 16px;
    font-family: $outfit-light;
    font-size: 12px;
    color: red;
    line-height: 16px;
  }

  &__submit-btn {
    position: relative;

    i {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }

    &--loading {
      span {
        opacity: 0;
      }
    }
  }
}

@media (max-width: 468px) {
  .landing-contact-us {
    &__input-box {
      width: 100vw;
      padding: 0 16px;
    }
  }
}
