.breadcrumbs {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 18px;
  flex-wrap: wrap;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $color-text-shadow;
  cursor: pointer;

  &:not(:last-child) {
    &::after {
      content: "/";
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
    }
  }

  &.isActive {
    color: $color-text;
  }

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
  }

  &:last-child {
    cursor: default;
  }
}
