.link {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ebecf0;
  width: fit-content;
}

.link-item {
  font-size: 16px;
  font-weight: 600;
  color: $color-text;
  cursor: pointer;
  position: relative;
  padding: 7px 0;

  &:not(:last-child) {
    margin-right: 23px;
  }

  &:only-child {
    margin-right: 0;
  }

  &:nth-child(2) {
    margin-right: 0;
  }

  &.isActive {
    color: $color-main;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -2px;
      left: 0;
      background-color: $color-main;
    }
  }

  &.isDisabled {
    cursor: default;
  }
}
