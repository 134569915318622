.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }

  .modal-measurement-types & {
    margin-top: 10px;
    margin-bottom: 12px;
  }
}

.form-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: $color-text-high;
  height: 48px;
  min-height: 48px;
  width: 100%;
  background-color: $color-highlight;
  padding: 12px 16px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  overflow: hidden;
  text-overflow: ellipsis;

  .form-wrapper & {
    padding-right: 40px;
  }

  &.is-disabled,
  &:disabled {
    cursor: default;
  }

  &::placeholder {
    font-weight: 500;
    color: $color-text-shadow;
  }

  .form-parameters-input & {
    min-width: 238px;
  }

  @media (max-width: 968px) {
    font-size: 16px;
  }
}

%label {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 16px;

  .step & {
    padding-left: 0;
  }

  .form-start-label & {
    padding-left: 5px;
  }
}

.form-label {
  @extend %label;
  font-weight: bold;
  color: $color-text-high;
  opacity: 0.8;

  .form-parameters-input & {
    padding-left: 8px;
  }
}

.modal-cols--group {
  margin-top: 0;
  padding-top: 0;
}

.groups {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.form-group-label,
.group-label {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: $color-text-high;
}

.form-group-label {
  grid-column: 1 / -1;
  padding-top: 10px;
}

.form-group-header {
  grid-column: 1 / -1;
  display: flex;
  align-items: end;
  justify-content: space-between;

  &-add {
    padding-bottom: 16px;
    margin-bottom: 8px;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 1px solid $color-primary;
  }
}

.size-chart-button {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: $color-main;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  &--disabled {
    cursor: default;
    opacity: 0.7;
  }
  &__tooltip {
    display: none;
  }
  &:hover {
    .size-chart-button__tooltip {
      display: block;
      position: absolute;
      width: 105px;
      right: 0;
      top: 100%;
      text-align: center;
      font-size: 10px;
      background-color: #fde8e7;
      color: #f26563;
      border-radius: 6px;
    }
  }
}

.group-label {
  margin-bottom: 10px;
}

.form-sublabel {
  @extend %label;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.6);
}

.form-check {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.form-check-input {
  margin-right: 12px;
  width: 16px;
  height: 16px;
}

.form-check-label {
  font-size: 14px;
  font-weight: bold;
  color: $color-text-high;
}

.form-wrapper {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.form-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;

  .isDisabled & {
    cursor: default;
  }
}

.form-error {
  background-color: #fde8e7;
  color: rgba(255, 40, 37, 0.6);
  font-size: 12px;
  font-weight: 400;
  padding: 2px 16px;
}

.form-success-msg {
  background-color: $success-bg;
  color: $success-msg;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 16px;

  &-bold {
    margin-left: 3px;
    font-weight: 600;
  }

  &-warning {
    background-color: $warning-bg;
    color: $warning-msg;
  }
}

.form-input-error {
  border-color: rgba(255, 40, 37, 0.6) !important;
}

.form-recovery {
  font-size: 12px;
  font-weight: 400;
  color: $color-main;
  padding: 0 16px;
  cursor: pointer;

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
  }
}

.form-radiobuttons {
  display: inline-flex;
  overflow: hidden;
  padding: 5px 4px;
  border-radius: 6px;
  background-color: $color-primary;
}

.form-radio-input {
  display: none;

  &:checked + .form-radio-label {
    background-color: $color-highlight;
    color: $color-main;
  }
}

.form-radio-label {
  font-size: 14px;
  font-weight: 500;
  padding: 9px 13px;
  line-height: 1.43;
  letter-spacing: 0.28px;
  border-radius: 4px;
  background-color: transparent;
  color: $color-text;
  cursor: pointer;
  transition: background-color 0.1s;
}

.form-gender {
  margin-bottom: 32px;

  .modal-product-type & {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .modal-event-measurements & {
    margin-bottom: 10px;
  }
}

.form-gender-title {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: $color-text-high;

  .modal-product-type & {
    color: $color-text-high;
    opacity: 0.8;
  }
}

.form-parameters-input {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;

  .modal-event-measurements & {
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    .modal-measurements & {
      display: block;
    }
  }
}

.form-label-star {
  color: #787878;
}

.form-custom-file {
  display: flex;
  flex: 1;
  gap: 16px;
  flex-wrap: wrap;

  &-label {
    width: 100%;
    margin: -4px 0 -8px;
  }

  &-input-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-input {
    cursor: pointer;
  }

  &-submit {
    padding: 0 28px;
    width: auto;
  }
}

.loading-file-block {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;

  &-description {
    padding-top: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    color: $color-text-grey;
    letter-spacing: 0.03em;
  }

  &-inputs-box {
    padding-bottom: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;

    &.recommended-box {
      padding-top: 8px;
      margin-bottom: -12px;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: end;
    }
  }

  &-calculate-box {
    display: flex;
    align-items: center;
    padding-top: 16px;
    gap: 0 24px;

    &-btn {
      max-width: 33%;
    }
  }
}

.calculate-file-line {
  border-top: 1px solid $color-primary;
}
