.landing-banner {
  flex-direction: column;

  & &__title {
    font-family: $oswald-regular;
    font-size: 54px;
    text-transform: uppercase;
    letter-spacing: 2px;
    max-width: 910px;
    padding: 0 8px;
    text-align: center;
    margin: 0 auto 20px;

    span {
      font-family: $oswald-extra-light;
      font-weight: 100;
      letter-spacing: 1px;
    }
  }

  & &__description {
    font-family: $outfit-light;
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 170px;
  }

  &__contact-us-btn {
    margin-bottom: 120px;
  }
}

@media (max-width: 1024px) {
  .landing-banner {
    & &__description {
      margin-bottom: 80px;
    }
  }
}

@media (max-width: 468px) {
  .landing-banner {
    & &__title {
      font-size: 36px;
    }

    & &__description {
      font-size: 18px;
    }
  }
}

