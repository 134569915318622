.mobile-app-container {
  justify-content: space-evenly;
  @media (max-width: 600px) {
    align-items: center;
    flex-direction: column;
  }

  .mobile-app-item {
    padding: 25px 20px 30px;
    text-align: center;
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
      color: #868fa0;
    }
    &__qr-code {
      display: block;
      width: 200px;
      margin-bottom: 10px;
    }

    .badge-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #000000;
      color: #ffffff;
      padding: 10px 20px;
      border-radius: 4px;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
    }

      .text-brand {
        font-size: 18px;
        font-weight: 700;
    }

    .google-play-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-image: url("../../img/google-play.svg");
    }
    .app-store-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-image: url("../../img/app-store.svg");
    }
  }
}
