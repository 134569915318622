.plug {
  border-radius: $border-radius;
  height: 100%;
  width: 100%;
  background-color: $color-highlight;
  margin-top: 22px;
}

.plug-header {
  height: 56px;
  width: 100%;
  display: block;
  background-color: $color-primary;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.plug-message {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #a3aed0;
  text-align: center;
}

.plug-icon {
  height: 27vh;
  width: 100%;
}

.plug-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 50vh;
}

.plug-load.loader {
  width: 10vh;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plug-load-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plug-title {
  margin-top: 117px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 198%;
  color: #a3aed0;
}
