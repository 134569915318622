.step {
  padding: 32px 20px;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  width: 82%;
  gap: 5vw;

  &.step-user {
    display: flex;
  }

  @media (max-width: 968px) {
    grid-template-columns: minmax(100px, 1fr);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
}

.step-col {
  display: flex;
  flex-direction: column;
  gap: 22px;

  @media (max-width: 968px) {
    overflow: auto;
  }
}

.step-range-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
