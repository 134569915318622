.dropdown {
  position: relative;
}

.dropdown-form {
  position: relative;
}

.dropdown-input-container {
  padding-right: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: $color-text-high;
  min-height: 48px;
  width: 100%;
  background-color: $color-highlight;
  padding: 8px 50px 7px 16px;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  flex-wrap: wrap;
  gap: 8px;

  .form-wrapper & {
    padding-right: 40px;
  }

  &:disabled {
    cursor: default;
  }
}

.dropdown-input {
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: $color-text-high;
  height: 31px;
  background-color: transparent;
  flex-grow: 1;

  &::placeholder {
    font-weight: 500;
    color: $color-text-shadow;
  }
}

.dropdown-no-result {
  width: 100%;
  color: $color-text-shadow;
  font-weight: 400;
}

.dropdown-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;

  .dropdown.isActive & {
    transform: rotate(180deg);
  }
}

.dropdown-list {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  border-radius: $border-radius;
  box-shadow: $color-shadow;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  z-index: 2;
  max-height: 147px;

  .dropdown.isActive & {
    display: block;
  }
}

.dropdown-item {
  background-color: $color-highlight;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: $color-text-high;

  &.isActive,
  &:hover {
    background-color: #f9f9f9;
    color: $color-main;
    cursor: pointer;
  }
}

.dropdown-selected {
  font-size: 14px;
  font-weight: bold;
  color: $color-main;
  background-color: $color-primary;
  height: 31px;
  border: none;
  border-radius: 60px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 14px;
  cursor: pointer;

  & + .dropdown-input::placeholder {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;
    display: none !important;
    width: 0;
    opacity: 0;
    visibility: hidden;
    left: -9999px;
  }

  & + .dropdown-input {
    width: 50px;
  }
}

.dropdown-selected-icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}
