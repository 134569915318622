.dashboard-card-wrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
  }
}

.dashboard-card {
  flex: 1 1 auto;
  padding: 14px 30px;
  border-radius: 24px;
  background-color: $color-highlight;

  &:nth-child(1) {
    min-width: 509px;

    @media (max-width: 580px) {
      min-width: unset;
    }
  }
}

.dashboard-card-title {
  display: block;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: $color-text;
}

.dashboard-card-number {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: $color-text-high;
}

.dashboard-summary {
  width: 100%;
  max-width: 509px;
  padding: 30px;
  border-radius: 24px;
  background-color: $color-highlight;

  @media (max-width: 968px) {
    max-width: 100%;
  }
}

.dashboard-events-title {
  display: inline-block;
  margin-bottom: 19px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $color-text-high;
}

.dashboard-status-wrapper {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.dashboard-event-status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.dashboard-status-color {
  width: 12px;
  height: 12px;
  border-radius: 4px;

  &.inProgress {
    background-color: $color-main;
  }

  &.completed {
    background-color: #42d678;
  }

  &.canceled {
    background-color: #f26563;
  }

  &.scheduled {
    background-color: #61758a;
  }
}

.dashboard-status-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $color-text;
}

.dashboards-events {
  display: flex;
  gap: 30px;

  @media (max-width: 968px) {
    flex-wrap: wrap;
    gap: 15px;
  }
}

.dashboard-events-list {
  flex: 1 1 auto;
  padding: 30px;
  border-radius: 24px;
  background-color: $color-highlight;
}

.dashboard-list {
  max-height: 356px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-main;
    border-radius: 100vw;
  }
}

.dashboard-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  padding: 8px 37px 8px 25px;
  border-bottom: 1px solid $color-primary;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 65px;
    color: $color-main;
    background-color: $color-main;
    border-radius: 4px;
  }
}

.dashboard-info-title {
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $color-text-high;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-info-text {
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-info-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;
}
