.main-container {
  background-color: $color-secondary;
  padding: 0 31px;
  min-height: calc(100vh - 83px);
  padding-bottom: 50px;

  @media (max-width: 500px) {
    padding: 0 16px;
  }
}

.info-container {
  min-height: auto;
  padding-bottom: 6px;
}
