.sales {
  background-color: $color-highlight;
  border-radius: $border-radius;
  padding: 20px;
  margin-top: 14px;
  column-count: 2;
  column-gap: 50px;

  &.sales-parent {
    column-count: 1;
  }

  @media (max-width: 968px) {
    column-count: 1;
  }
}

.sales-item {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;
  color: $color-text-high;
  white-space: pre-wrap;
  letter-spacing: 0.28px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.sales-val {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: $color-text;

  @media (max-width: 500px) {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.sales-status {
  width: fit-content;
  margin-left: 20px;
}
