*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*:active,
*:focus {
  font-family: "Inter", sans-serif;
  outline: none;
}

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-secondary;

  &.overflow-hidden {
    overflow: hidden;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 968px) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
