.status {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  padding: 3px 8px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61758a;
  background-color: #f0f5f9;
  white-space: nowrap;

  .measurings-status & {
    width: fit-content;
  }
}

.status-success {
  color: #42d678;
  background-color: #e8f7ee;
}

.status-warning {
  color: #fea800;
  background-color: #fff9e2;
}

.status-danger {
  color: #f26563;
  background-color: #fde8e7;
}

.status-bar-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: 8px;
  min-width: 40px;

  .sales-bar & {
    width: 85px;
    min-width: 85px;
  }
}

.status-bar {
  width: 100%;
  height: 100%;
  background-color: $color-primary;
  border-radius: 4px;
}

.status-line {
  height: 100%;
  background-color: $color-main;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
