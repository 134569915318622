.toggle-switcher-container {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 10px;
  outline: none;

  @media (max-width: 768px) {
    margin: 10px 0;
    align-self: flex-end;
  }
}
.toggle-switcher {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 25px;

  &__label {
    font-size: 14px;
    font-weight: 500;
    color: #464646;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
  }

  &__position-toggle {
    position: absolute;
    top: 4px;
    left: 4rem;
    bottom: 0;

    @media (max-width: 768px) {
      left: -11.5rem;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #868fa0;
    border-radius: 28px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
  }

  input:checked + .slider {
    background-color: $color-main;
  }

  input:checked + .slider:before {
    transform: translateX(28px);
  }
}