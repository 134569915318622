.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
}

.login-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;

  @media (max-width: 968px) {
    display: none;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 7vw;
  background-color: #f3f6f8;
}

.login-back {
  justify-content: unset;
  padding-top: 3vh;
}

.login-title {
  display: block;
  font-size: 54px;
  font-weight: 400;
  color: $color-text-high;
  line-height: 1;

  &.login-title-space {
    margin-bottom: 7vh;
  }
}

.login-subtitle {
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 28px;
  line-height: 1.4;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.login-terms {
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $color-text;
  margin-top: 20px;
}

.login-link {
  text-decoration: underline;
  cursor: pointer;
}

.login-line {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-text-high;
  margin: -8px 0;

  &::before,
  &::after {
    content: "";
    background-color: #cbcbcb;
    height: 1px;
    width: 100%;
  }

  &::before {
    margin-right: 13px;
  }

  &::after {
    margin-left: 13px;
  }
}

.login-info {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin: 16px 0;
}

.login-recover-link {
  font-size: 12px;
  font-weight: normal;
  color: $color-main;
  height: fit-content;
  padding-left: 16px;
  margin: 0;
  margin-top: 6px;
}

.login-loading {
  pointer-events: none;
}