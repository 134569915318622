.isHidden {
  display: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
  opacity: 1;

  &__sales-rep {
    margin-top: 8px;
  }

  &__hidden {
    opacity: 0;
    visibility: hidden;
  }

  .checkbox {
    &__handler {
      margin-left: 5px;
      margin-right: 10px;
    }

    &__handler,
    &__label {
      cursor: pointer;
    }
  }

  .disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}