.filter {
  position: absolute;
  display: none;
  right: 0;
  top: calc(100% + 10px);
  width: fit-content;
  min-width: 390px;
  background-color: $color-highlight;
  border-radius: $border-radius;
  box-shadow: 0 -1px 6px 0 rgba(233, 237, 247, 0.55),
    0 4px 6px 0 rgba(233, 237, 247, 0.55);
  padding: 8px 0 0 20px;
  z-index: 7;
  height: unset;
  max-height: 730px;

  &.isActive {
    display: block;
  }

  @media (max-width: 968px) {
    min-width: 320px;
  }
}

.filter-form-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-title {
  font-size: 20px;
  font-weight: bold;
  color: $color-text-high;
}

.filter-clear {
  width: auto;
  font-size: 18px;
}

.filter-subtitle {
  font-size: 17px;
  font-weight: 600;
  color: $color-text-high;
  margin-bottom: 14px;
}

.filter-form {
  padding: 19px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
  }

  &:last-child {
    padding-bottom: 30px;
  }
}

.filter-fields {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;

  .filter-fields & {
    padding: 0;
  }
}

.filter-radio {
  display: block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid $color-main;
  border-radius: 50%;
  margin-right: 12px;
}

.form-radio-input {
  display: none;
}

.form-radio-input:checked ~ .filter-radio {
  border: 5px solid $color-main;
}

.filter-buttonset {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &.filter-form-buttonset {
    column-gap: 12px;
    row-gap: 8px;
  }
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: $color-text;
  height: 34px;
  padding: 0 12px;
  background-color: transparent;
  border-radius: $border-radius;
  border: 1px solid #cbcbcb;
  cursor: pointer;

  &.isActive {
    background-color: $color-main;
    color: $color-highlight;
  }
}

.filter-controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filter-link {
  display: flex;
  align-items: center;
  height: 34px;
  background-color: transparent;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  color: $color-main;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
