.docs-container {
  max-width: 1056px;
  margin: 50px auto;
  padding: 0 16px;

  a {
    color: $color-main;

    &:hover {
      color: #3731f1;
    }
  }
}

.docs-nav {
  display: flex;
  flex-direction: column;
}

.docs-nav-link {
  display: inline-block;
  margin-bottom: 4px;
  color: $color-main;

  &:hover {
    color: #3731f1;
  }
}

.docs-title {
  text-align: center;
  margin-bottom: 24px;
}

.docs-subtitle {
  margin-bottom: 4px;
  margin-top: 24px;
  scroll-margin-top: 100px;
}
