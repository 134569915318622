.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 83px;
  background-color: $color-highlight;
  padding: 0 31px;
  border-bottom: 1px solid #dadada;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;

  @media (max-width: 500px) {
    padding: 0 16px;
  }
}

.header-logo {
  height: 53px;
  cursor: pointer;

  @media (max-width: 408px) {
    .header.menu-open & {
      display: none;
    }
  }
}

.header-icon {
  width: 100%;
  height: 100%;
  width: 103px;
}

.header-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  height: 100%;
  margin-right: 30px;

  .event-table-parent & {
    justify-content: flex-end;
  }

  @media (max-width: 968px) {
    display: none;

    .event-td & {
      display: flex;
    }
  }

  .header.menu-open & {
    display: flex;
  }
}

.header-dropdown-name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $color-text-high;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.header-dropdown-icon {
  width: 16px;
  height: 16px;
  margin-left: 15px;
}

.header-dropdown-list {
  position: absolute;
  top: calc(100% - 15px);
  right: 0;
  width: fit-content;
  background-color: $color-highlight;
  padding: 9px;
  border-radius: $border-radius;
  display: none;
  box-shadow: $color-shadow;

  &.isActive {
    display: block;
  }

  .get-mobile-app {
    margin-top: 14px;
  }
}

.header-logout {
  font-size: 14px;
  font-weight: 500;
  background-color: #ffdddd;
  border-color: #ffdddd;
  white-space: nowrap;
  padding: 0 16px;
  border-radius: $border-radius;

  .btn-icon {
    margin-right: 6px;
  }
}

.header-controls {
  display: flex;
  align-items: center;
  height: 100%;

  // .menu-bar.menu-open &{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100vh;
  //   background-color: #ccc;
  // }
}

.header-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;

  @media (max-width: 968px) {
    display: none;
  }

  @media (max-width: 968px) and (min-height: 340px) {
    .header.menu-open & {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      margin-left: 0;
      background-color: $color-secondary;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  @media (max-height: 340px) and (max-width: 968px) {
    .header.menu-open & {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      height: 100vh;
      margin-left: 0;
      background-color: $color-secondary;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 10px;
  height: 100%;
  color: #868fa0;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &.isActive,
  &:hover {
    border-color: $color-main;
    color: $color-main;
  }

  @media (max-width: 968px) and (min-height: 500px) {
    .header.menu-open & {
      height: auto;
      margin: 20px;
      color: $color-text-high;

      &.isActive {
        color: $color-main;
      }
    }
  }

  @media (max-width: 968px) and (max-height: 500px) {
    .header.menu-open & {
      height: auto;
      margin: 8px;
      color: $color-text-high;

      &.isActive {
        color: $color-main;
      }
    }
  }
}

.header-link {
  height: 100%;
  display: flex;
  align-items: center;
}
