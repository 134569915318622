.alert-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 408px;
  height: auto;
  margin: 0 auto;
  padding: 24px 21px;
  text-align: center;
  background: $color-highlight;
  box-shadow: 0px 4px 6px rgba(233, 237, 247, 0.55),
    0px -1px 6px rgba(233, 237, 247, 0.55);
  border-radius: 10px;

  @media (max-width: 440px) {
    width: 300px;
  }
}

.alert-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 auto 22px;
  border-radius: 50%;

  &.danger {
    background-color: #fde8e7;
  }

  &.success {
    background-color: #e8f7ee;
  }

  .notification-wrapper & {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px;
    top: 20px;
  }
}

.alert-icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;

  .notification-wrapper & {
    width: 14px;
    height: 14px;
  }
}

.alert-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: $color-text-high;
}

.alert-subtitle {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: $color-text;
}

.alert-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
