.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 15px;
  padding-right: 43px;

  @media (max-width: 968px) {
    gap: 10px 0;
    flex-wrap: wrap;
  }
}

.pagination-list {
  display: flex;
  align-items: center;
}

.pagination-item {
  min-width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text;
  cursor: pointer;

  &.isActive {
    background-color: $color-main;
    border-radius: 3px;
    color: $color-highlight;
  }

  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover:not(.isActive):not(.isDisabled) {
    color: $color-main;
  }
}

.pagination-btn {
  margin: 0 4px;
}

.pagination-icon {
  width: 16px;
  height: 16px;
}

.pagination-generate {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $color-main;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.pagination-generate-icon {
  margin-right: 7px;
  width: 20px;
  height: 20px;
}
