.bulk-report {
  position: relative;
  padding: 8px 16px 8px 40px;
  border-radius: 8px;
  font-size: 12px;
  max-width: 408px;

  &.bulk-report-warning {
    background-color: #fff4ec;
    color: #b95000;

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4.667a.667.667 0 0 0-.667.666V8a.667.667 0 0 0 1.334 0V5.333A.667.667 0 0 0 8 4.667zm.614 5.746a.505.505 0 0 0-.06-.12l-.08-.1a.666.666 0 0 0-.727-.14.765.765 0 0 0-.22.14.665.665 0 0 0-.14.727.6.6 0 0 0 .36.36.626.626 0 0 0 .507 0 .6.6 0 0 0 .36-.36.666.666 0 0 0 .053-.253.904.904 0 0 0 0-.134.426.426 0 0 0-.053-.12zM8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zm0 12A5.333 5.333 0 1 1 8 2.667a5.333 5.333 0 0 1 0 10.666z' fill='%23B95000'/%3E%3C/svg%3E%0A");
    }
  }

  &.bulk-report-error {
    background-color: #fde8e7;
    color: #ff2825;

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4.667a.667.667 0 0 0-.667.666V8a.667.667 0 0 0 1.334 0V5.333A.667.667 0 0 0 8 4.667zm.614 5.746a.505.505 0 0 0-.06-.12l-.08-.1a.666.666 0 0 0-.727-.14.765.765 0 0 0-.22.14.665.665 0 0 0-.14.727.6.6 0 0 0 .36.36.626.626 0 0 0 .507 0 .6.6 0 0 0 .36-.36.666.666 0 0 0 .053-.253.904.904 0 0 0 0-.134.426.426 0 0 0-.053-.12zM8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zm0 12A5.333 5.333 0 1 1 8 2.667a5.333 5.333 0 0 1 0 10.666z' fill='%23FF2825'/%3E%3C/svg%3E%0A");
    }
  }
}
