.menu-bar {
  display: none;

  @media (max-width: 968px) {
    position: relative;
    display: inline-block;
    z-index: 2;
    float: right;
    height: 30px;
    width: 30px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    cursor: pointer;
    border: none;
    background-color: transparent;
    vertical-align: center;
  }
}

.menu-icon {
  width: 20px;
  // height: 30px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  // top: 50%;
}

.bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background-color: $color-text-high;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  &.bar1 {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  &.bar2 {
    -webkit-transform: translateY(6px) rotate(0deg);
    transform: translateY(6px) rotate(0deg);
  }
}

.menu-open {
  .menu-bar {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.1s;

    &.bar1 {
      -webkit-transform: translateY(4px) rotate(45deg);
      transform: translateY(4px) rotate(45deg);
    }

    &.bar2 {
      -webkit-transform: translateY(3px) rotate(-45deg);
      transform: translateY(3px) rotate(-45deg);
    }
  }
}
