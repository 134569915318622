.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 38vw;
  z-index: 7;
  background-color: $color-highlight;
  max-height: 670px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  &.modal-product-type {
    max-width: 549px;

    @media (max-width: 500px) {
      max-width: unset;
      min-width: 85vw;
    }
  }

  &.modal-measurement-types {
    max-width: 549px;

    @media (max-width: 500px) {
      max-width: unset;
      min-width: 85vw;
    }
  }

  @media (max-width: 968px) {
    min-width: 65vw;
  }

  @media (max-width: 500px) {
    min-width: 90vw;
  }

  @media (max-width: 968px) and (max-height: 500px) {
    max-height: 85vh;
    overflow: hidden;
  }
}

.modal-header {
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-radius: 12px 12px 0 0;

  .modal-user & {
    padding: 20px;
  }
}

%modal-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: $color-main;
}

.modal-title {
  @extend %modal-title;
}

.modal-subtitle {
  @extend %modal-title;
  margin-bottom: 10px;
  margin-top: 20px;

  &.isDisabled {
    color: #868fa0;
  }
}

.modal-description {
  padding: 20px 20px 0;
  color: $color-text;
  letter-spacing: 0.03em;
  font-weight: 500;

  &-inside {
    padding: 16px 0 0;
    margin-bottom: 20px;
  }
}

.modal-body {
  padding: 0 20px;
}

.modal-body-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;

  @media (max-width: 968px) and (max-height: 500px) {
    max-height: 65vh;
  }
}

.modal-step {
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $color-main;
  margin-right: 12px;
  font-size: 16px;
  font-weight: 500;
  color: $color-highlight;

  .modal-subtitle.isDisabled & {
    background-color: #868fa0;
  }
}

.modal-back {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 6;
}

.modal-close {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-close-icon {
  width: 100%;
  height: 100%;
}

.modal-form {
  gap: 10px;

  &.form {
    gap: 10px;
  }

  .modal-product-type & {
    margin-top: 20px;
  }
}

.modal-form-head {
  padding: 0 20px 20px;

  .modal-body-wrapper form:nth-child(1) & .form {
    border-bottom: 1px solid $color-primary;
    padding-bottom: 20px;
  }

  .modal-event-measurements & {
    padding: 0 0 20px;
    margin-bottom: 20px;
  }

  .modal-event-no-measurements & {
    padding: 0 0 20px;
    margin-bottom: 20px;
  }
}

.modal-footer {
  background-color: #e9edf7;
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 12px 20px;
  margin-top: 20px;

  .modal-user & {
    padding: 20px;
  }

  .modal-product-type & {
    margin-top: 0;
  }

  .modal-measurement-types & {
    margin-top: 0;
  }

  .modal-event-measurements & {
    margin-top: 0;
  }

  .modal-event-no-measurements & {
    margin-top: 0;
  }

  .modal-measurements & {
    position: absolute;
    height: 70px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .modal-events-actions & {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.modal-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px 32px;
  margin-top: 10px;
  padding: 22px 20px 0;
  position: relative;

  .modal-measurements & {
    padding: 0;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
}

.modal-product .modal-body {
  padding-top: 20px;
}

.modal-product-form {
  &:not(:last-child) {
    padding-bottom: 2px;
  }

  &:last-child {
    padding-top: 12px;
  }
}

.modal-wrapper {
  display: flex;
  padding: 20px;
  gap: 24px;

  .modal-measurement-types & {
    padding-bottom: 10px;
  }

  .modal-measurements & {
    padding: 20px 20px 110px;

    @media (max-width: 1130px) {
      flex-wrap: wrap;
    }
  }
}

.modal-inner {
  flex: 1 1 50%;

  .modal-events-actions & {
    min-width: 350px;
    max-width: 350px;

    @media (max-width: 500px) {
      min-width: unset;
      max-width: unset;
    }
  }
}

.modal-tips-title {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: $color-text-high;

  .modal-product-type & {
    color: $color-text-high;
    opacity: 0.8;
  }

  .modal-measurement-types & {
    color: $color-text-high;
    opacity: 0.8;
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.modal-tips-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .measurement-list & {
    gap: 8px 12px;
    margin-bottom: 0;
  }
}

.modal-tips-item {
  padding: 8px 12px;
  background-color: $color-highlight;
  color: $color-text;
  border: 1px solid #cbcbcb;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  cursor: pointer;

  &.isActive {
    background-color: $color-main;
    color: $color-highlight;
    border: 1px solid $color-main;
  }
  
  &.isHovered {
    background-color: $color-primary;
  }

  .modal-measurement-types & {
    padding: 7px 12px;
  }

  @media (max-width: 968px) {
    word-break: break-all;
  }
}

.modal-measurements {
  min-width: 835px;

  @media (max-width: 1130px) {
    min-width: 75vw;
  }

  @media (max-width: 600px) {
    min-width: 90vw;
  }

  @media (max-width: 968px) and (max-height: 500px) {
    max-height: 85vh;
    overflow: hidden;
  }
}

.modal-event-measurements {
  min-width: auto;
}

.modal-event-no-measurements {
  min-width: 549px;
}

.modal-image {
  z-index: 2;

  .modal-measurement-types & {
    width: 244px;
    height: 244px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }
}

.modal-image-tips {
  width: 244px;
  height: 244px;
  object-fit: contain;

  .modal-measurement-types & {
    width: 244px;
    height: 244px;
    object-fit: cover;
  }
}

.modal-image-delete {
  width: 244px;
  padding: 20px 11px 10px;
  margin-top: -25px;
  background-color: $color-primary;
  border-radius: 8px;
  z-index: 1;
}

.modal-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  border: 1px solid #a3aed0;

  .modal-measurements & {
    height: 250px;
  }
}

.modal-image-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #a3aed0;

  .modal-add-image & {
    color: $color-main;
  }
}

.modal-add-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  border: 1px dashed #a3aed0;
}

.modal-no-measurements-text {
  position: absolute;
  left: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #868fa0;

  .modal-measurements & {
    left: 0;
  }

  @media (max-width: 968px) {
    left: 0;
    top: -20px;

    .modal-events-actions & {
      left: 20px;
      top: 0px;
    }
  }
}

.modal-gender {
  padding: 0 20px;
}

.modal-tips {
  .modal-manage-user-wrapper & {
    margin: 20px;
  }
}

.modal-events-actions {
  display: flex;
  margin-bottom: 90px;

  @media (max-width: 968px) {
    display: block;
  }
}

.modal-events-actions-inner {
  min-width: 485px;
  max-width: 485px;
  padding-top: 20px;

  @media (max-width: 500px) {
    min-width: unset;
    max-width: unset;
  }
}

.modal-tips-wrapper {
  @media (max-width: 500px) {
    margin-top: 20px;
  }
}

.modal-excel-files {
  &-list {
    flex: 1;
    margin-top: -8px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 0.03em;
      color: $color-text;
      font-weight: 500;
      line-height: 20px;
      padding: 3px 0 4px;
    }
  }
}

.modal-back:has(.modal-hide) {
  display: none;
}

.modal-size-chart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 38vw;
  width: 90%;
  max-width: 700px;
  z-index: 7;
  background-color: #fff;
  max-height: 670px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  .modal-body-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }
}

.modal-view-size-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  aspect-ratio: 16 / 9;
  opacity: 0;
  &--loaded {
    opacity: 1;
  }
}

.modal-view-size-chart img {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.modal-view-size-chart--portrait img {
  width: auto;
  height: 100%;
  object-fit: contain; 
}


@media (max-width: 768px) {
  .modal-size-chart {
    width: 90%;
    max-height: 80vh;
    .modal-body-wrapper {
      padding: 8px;
    }
  }
  
}

.modal-view-size-chart__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
