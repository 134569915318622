.landing-header,
.landing-header * {
  font-family: $outfit-regular;
}

.landing-header {
  position: relative;
  margin-bottom: 176px;
  padding: 0 40px;
  font-size: 14px;
  text-transform: uppercase;

  &__menu-container {
    position: relative;
    z-index: 1;
    flex: 1;
    justify-content: space-between;
  }

  &__logo-box {
    position: relative;
    z-index: 1;
    min-height: $logo-box-height;
    justify-content: space-between;
  }

  &__menu-toggle {
    position: relative;
    display: none;
    width: 50px;
    height: 50px;
    cursor: pointer;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__logo {
    display: inline-block;
    margin-right: 80px;
  }

  &__menu {
    list-style: none;
    gap: 40px;
  }

  &__auth-box {
    gap: 16px;
    flex-direction: row-reverse;

    & .landing-header__auth-btn:nth-child(1) {
      &:hover {
        color: $dark-text;
        background: #fff;

        & ~ .landing-header__auth-btn {
          color: #fff;
          background: transparent;
        }
      }
    }

    & .landing-header__auth-btn:nth-child(2) {
      color: $dark-text;
      background: #fff;
    }
  }

  &__auth-btn {
    padding: 0 34px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 16px;
    transition: all 0.25s ease-in-out;
  }
}

@media (max-width: 900px) {
  .landing-header {
    position: relative;
    flex-direction: column;

    &__logo-box {
      width: 100%;
      position: relative;
      z-index: 10;
    }

    &__menu-toggle {
      display: block;

      & .icon {
        transition: opacity $landing-transition-time ease-out;
      }
    }

    &__menu-container {
      position: absolute;
      top: $logo-box-height;
      left: 0;
      width: 100%;
      padding-bottom: 120px;
      flex-direction: column-reverse;

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: calc(100% + ($logo-box-height * 1.5));
        z-index: -1;
        background: $main-dark-bg;
      }
    }

    &__logo {
      margin-right: 0;
    }

    &__menu {
      flex-direction: column;
    }

    &__auth-box {
      margin-bottom: 80px;
    }
  }

  #landing-menu-toggle {
    &:not(:checked) ~ .landing-header {
      & .icon-landing-burger-closed {
        opacity: 1;
      }

      & .icon-landing-burger-opened {
        opacity: 0;
      }

      & .landing-header__menu-container {
        opacity: 0;
        transition: opacity $landing-transition-time ease-out,
          z-index 0s linear 1s;
        z-index: -1;
      }
    }

    &:checked ~ .landing-header {
      & .icon-landing-burger-closed {
        opacity: 0;
      }

      & .icon-landing-burger-opened {
        opacity: 1;
      }

      & .landing-header__menu-container {
        opacity: 1;
        transition: opacity $landing-transition-time ease-out, z-index 0s linear;
        z-index: 5;
      }
    }
  }
}

@media (max-width: 468px) {
  .landing {
    &__bg-img {
      &--left {
        left: -150px;
      }

      &--right {
        right: -150px;
      }
    }
  }
}
