.measurings {
  width: 100%;
  padding: 20px;
  background-color: $color-highlight;
  border-radius: 8px;
}

.measurings-status {
  display: flex;
  gap: 10px;
  margin-bottom: 17px;
}

.measurings-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.measurings-item {
  display: inline-block;
  min-width: 194px;
  padding: 20px;
  text-align: center;
  background-color: #e9edf7;
  border-radius: 8px;
}

.measurings-title {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;
}

.measurings-data {
  display: block;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: $color-text-high;

  &.no-data {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #aaa8eb;
  }
}

.measurement-list {
  max-height: 168px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 4px 4px rgba(183, 180, 180, 0.25);
  border-radius: 0 0 8px 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-main;
    border-radius: 100vw;
  }

  .modal-measurement-types & {
    padding: 0 8px 8px 8px;
  }
}

.measurement-table-list {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.measurement-list-icon {
  width: 14px;
  height: 14px;
  margin-left: 6px;
  transition: 0.2s ease-in-out;

  .showMore & {
    transform: rotate(180deg);
  }
}
