@import "./landing/header";
@import "./landing/banner";
@import "./landing/benefits";
@import "./landing/features";
@import "./landing/contactUs";

.landing {
  overflow: hidden;
  color: #fff;
  background: $dark-bg;

  &__bg-img {
    position: absolute;
    top: 50px;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &-footer {
    position: relative;
    z-index: 1;
    height: 100px;
    background: $main-dark-bg;
  }

  &__section-title {
    font-family: $oswald-regular;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &__section {
    padding-top: 120px;
  }

  &__main-btn {
    position: relative;
    z-index: 1;
    padding: 0 32px;
    height: 56px;
    color: #fff;
    background: $color-main;
    font-family: $outfit-regular;
    text-transform: uppercase;
    border: none;
    border-radius: 28px;
    cursor: pointer;
    letter-spacing: 0.5px;
  }

  &__box {
    padding: 0 80px;
    max-width: 1280px;
    margin: 0 auto;
  }
}

@media (max-width: 1100px) {
  .landing {
    &__box {
      padding: 0 40px;
    }
  }
}

@media (max-width: 468px) {
  .landing {
    &__bg-img {
      &--left {
        left: -150px;
      }

      &--right {
        right: -150px;
      }
    }

    &__box {
      padding: 0 16px;
    }
  }
}
