.image-selector {
  display: grid;
  grid-gap: 10px;
  overflow: hidden;
  width: fit-content;  
  &__title {
    color: #1e1e1e;
    opacity: 0.8;
    margin-bottom: 0;
    margin-left: 8px;
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  }
  
  &__picker {
    width: 244px;
    height: 244px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    border: 1px dashed #a3aed0;
    cursor: pointer;
  }
  
  &__text-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #5e5adb;
  }
  &__error-message {
    color: #ff2825;
    opacity: 0.8;
    margin-bottom: 0;
    margin-left: 8px;
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  }
  &__image {
    cursor: pointer;
    img {
      width: 244px;
      height: 244px;
      object-fit: contain;
    }
  }
 &__delete-action {
    width: 244px;
    padding: 10px 11px;
    margin-top: -25px;
    background-color: $color-primary;
    border-radius: 8px;
    z-index: 1; 
   cursor: pointer;
    .icon-delete {
      width: 18px;
      height: 20px;
    }
 }
}
