.event-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 0;

  .info-container & {
    padding: 18px 0 10px;
  }

  @media (max-width: 968px) {
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.event-title {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: $color-text-high;
}

.event-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .btn-add:not(:last-child) {
    background-color: transparent;
    color: $color-main;

    .btn-icon {
      display: none;
    }
  }

  @media (max-width: 660px) {
    flex-wrap: wrap;
    width: 100%;
  }
}

.events-btn {
  margin-left: 10px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
}

.event-table {
  width: 100%;
  border: none;
  border-radius: $border-radius;
}

.event-table-container {
  overflow: auto;
  border-radius: $border-radius;
  background-color: $color-highlight;
}

.event-th,
.event-td {
  border-bottom: 1px solid #e9edf7;
  vertical-align: top;
}

.event-th {
  font-size: 14px;
  font-weight: 500;
  color: $color-text;
  text-align: left;
  white-space: nowrap;
  background-color: $color-primary;
  padding: 20px;
}

.event-td {
  font-size: 14px;
  font-weight: 400;
  color: $color-text;
  background-color: $color-highlight;
  padding: 25px 20px;

  &.is-color {
    color: #a3aed0;
  }
}

.event-period {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &::before {
    content: "";
    display: block;
    width: 11px;
    min-width: 11px;
    height: 11px;
    background-color: $color-main;
    border-radius: 50%;
    margin-right: 6px;
    border: 3px solid $color-main;
  }
}

.event-period-start {
  &::before {
    border-color: $color-primary;
  }
}

.event-period-end {
  &::after {
    content: "";
    height: 100%;
    width: 2px;
    border-left: 2px dashed $color-main;
    position: absolute;
    top: 0;
    left: 5.5px;
    transform: translate(-50%, -50%);
  }
}

.event-table-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.event-table-action {
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.event-table-edit {
  background-color: #e8f7ee;
}

.event-table-add {
  background-color: #fff9e2;
}

.event-table-update {
  background-color: #fde8e7;
}

.event-table-edit--purple {
  background-color: transparent;
}

.event-table-icon {
  width: 100%;
  height: 100%;
}

.event-table-action-icon {
  width: 16px;
  height: 16px;
}

.event-table-tooltip {
  visibility: hidden;
  position: absolute;
  width: 125px;
  bottom: 135%;
  left: 0;
  padding: 6px 0;
  margin-left: -60px;
  text-align: center;
  font-size: 12px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

  .event-table-action:hover & {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    bottom: -8px;
    box-sizing: border-box;
    border: 4px solid black;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -3px 3px 8px 0 rgb(0 0 0 / 15%);
  }

  .link:hover & {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-edit {
  background-color: #e8f7ee;
  color: #42d678;

  &::after {
    border-color: transparent transparent #e8f7ee #e8f7ee;
  }
}

.tooltip-add {
  background-color: #fff9e2;
  color: #fea800;

  &::after {
    border-color: transparent transparent #fff9e2 #fff9e2;
  }
}

.tooltip-update {
  background-color: #fde8e7;
  color: #f26563;

  &::after {
    border-color: transparent transparent #fde8e7 #fde8e7;
  }
}

.tooltip-common {
  width: auto;
  padding: 5px 10px;
  bottom: 105%;
  left: 28%;
  height: 36px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: #f0f5f9;
  color: #61758a;

  &::after {
    border-color: transparent transparent #f0f5f9 #f0f5f9;
    left: 36%;
  }
}

.event-progress {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-sort-icon {
  width: 8px;
  min-width: 8px;
  height: 7px;
  margin-left: 5px;
  cursor: pointer;
}

.event-form {
  background-color: $color-highlight;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  margin-top: 5px;
  margin-bottom: 15px;
}

.event-form-list {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  padding: 24px 20px;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.event-form-item {
  display: flex;
  align-items: center;
  color: #868fa0;
  font-size: 18px;
  font-weight: 500;

  &.isActive {
    color: $color-main;
  }

  &:not(:last-child) {
    &::after {
      content: "";
      width: 28px;
      height: 1px;
      background-color: #a3aed0;
      margin: 0 15px;
    }
  }
}

.event-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin-right: 12px;
  background-color: #868fa0;
  border-radius: 50%;
  color: $color-highlight;

  .event-form-item.isActive & {
    background-color: $color-main;
  }

  @media (max-width: 360px) {
    margin-right: 4px;
  }
}

.event-department {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 14px;
}

.event-footer {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  padding: 12px 20px;
  gap: 17px;
  padding-top: 2vh;

  @media (max-width: 968px) {
    flex-wrap: wrap;
  }
}

.event-footer-btn {
  width: fit-content;
  min-width: 245px;
  padding: 0 20px;
  font-weight: bold;

  @media (max-width: 500px) {
    .event-form & {
      width: 100%;
    }
  }
}

.event-table-controls {
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: $color-primary;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;

    @media (max-width: 968px) {
      margin-bottom: 5px;
    }
  }
}

.event-table-user {
  border: 1px solid $color-primary;
  border-radius: $border-radius;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 14px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    border-radius: 0;
  }
}

.event-table-parent {
  .event-th:last-child {
    width: 40%;
    text-align: right;
  }
}

.event-link {
  color: $color-main;
}

.recommended-size {
  width: 100%;
}
